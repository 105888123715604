<template>
  <div id="all-users">
    <div class="flex justify-end">
      <div class="flex items-center mx-4">
        <p class="text-card text-md font-semibold pr-2">Filter by</p>
      </div>
      <div class="mr-4">
        <el-input placeholder="Search" v-model="searchQuery" @input="search"></el-input>
      </div>
      <!-- New Button to Open Modal -->
      <div class="mr-4">
        <button @click="openModal" class="rounded text-white font-semibold bg-dashblack px-4 py-2">
          Add New User
        </button>
      </div>
    </div>
    <div class="mt-10">
      <el-table :data="userData" width="100%">
        <el-table-column label="S/N">
          <template slot-scope="scope">
            <span>{{ scope.row.id }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="username" label="Username">
        </el-table-column>
        <el-table-column prop="email" label="Email">
        </el-table-column>
        <el-table-column label="Role">
          <template slot-scope="scope">
            <span v-if="scope.row.role === 'super_admin'">
              Super Admin
            </span>
            <span v-else-if="scope.row.role === 'admin'">Admin</span>
            <span v-else>Moanee</span>
          </template>
        </el-table-column>
        <el-table-column label="Date Added">
          <template slot-scope="scope">
            <span>{{ scope.row.created_at | formatDate }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Status">
          <template slot-scope="scope">
            <span class="text-tablegreen" v-if="scope.row.is_active === 1">
              Active
            </span>
            <span class="text-red-600" v-else>
              Inactive
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div class="mt-10" v-if="user.allUsers.length > 0">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          style="float: right;" :current-page.sync="currentPage" :page-sizes="pageSizes" :page-size="user.perPage"
          layout="total, sizes, prev, pager, next, jumper" :total="user.usersTotal">
        </el-pagination>
      </div>
    </div>

    <!-- Dialog for Creating New System User -->
    <el-dialog :visible.sync="dialogVisible" width="80%">
      <div class="p-6">
        <h3 class="text-dashblack font-bold text-2xl pb-8">Create New System User</h3>
        <el-form ref="newSystemUser" :model="newSystemUserForm">
          <div class="grid grid-cols-2 gap-6">
            <el-form-item prop="username">
              <div class="flex flex-col">
                <label class="font-semibold">Name</label>
                <el-input v-model="newSystemUserForm.name" class="w-input" placeholder="Enter Full Name"></el-input>
              </div>
            </el-form-item>
            <el-form-item prop="email">
              <div class="flex flex-col">
                <label class="font-semibold">Username</label>
                <el-input v-model="newSystemUserForm.username" class="w-input" placeholder="Enter Username"></el-input>
              </div>
            </el-form-item>
          </div>
          <div class="grid grid-cols-2 gap-6 mt-6">
            <el-form-item prop="role">
              <div class="flex flex-col">
                <label class="font-semibold">Email</label>
                <el-input v-model="newSystemUserForm.email" class="w-input" placeholder="Enter Email"></el-input>
              </div>
            </el-form-item>
            <el-form-item prop="roles">
              <div class="flex flex-col">
                <label class="font-semibold">Roles</label>
                <el-select v-model="newSystemUserForm.roles" placeholder="Status" class="w-input">
                  <el-option v-for="item in roles" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
          </div>
          <div>
            <button @click.prevent="createNewUser"
              class="rounded text-white font-semibold bg-dashblack px-6 py-3 mt-4 w-32 focus-outline">
              Submit
            </button>
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import debounce from 'lodash.debounce'

export default {
  props: {
    userData: Array
  },
  data() {
    return {
      pageSizes: this.$store.state.pageSizes,
      dialogVisible: false,
      newSystemUserForm: {
        username: '',
        email: '',
        roles: '',
        name: ''
      },
      status: [
        {
          value: 'active',
          label: 'Active'
        },
        {
          value: 'inactive',
          label: 'Inactive'
        }
      ],
      roles: [
        {
          value: 'super_admin',
          label: 'Super Admin'
        },
        {
          value: 'admin',
          label: 'Admin'
        },
        {
          value: 'user',
          label: 'User'
        }
      ],
      input: '',
      value: '',
    }
  },
  computed: {
    ...mapState(['user']),
    currentPage: {
      get() {
        return this.user.userCurrentPage
      },
      set(value) {
        return this.$store.commit('mutate', {
          property: 'userCurrentPage',
          with: value
        })
      }
    },
    searchQuery: {
      get() {
        return this.user.searchQuery
      },
      set(value) {
        return this.$store.commit('mutate', {
          property: 'searchQuery',
          with: value
        })
      }
    },
    formattedRoles() {
      // Ensure that roles is always an array. If it's a string, wrap it in an array
      return Array.isArray(this.newSystemUserForm.roles)
        ? this.newSystemUserForm.roles
        : [this.newSystemUserForm.roles];
    }
  },
  methods: {
    ...mapActions(['getAllUsers', 'createSystemUser']),
    createNewUser() {
      this.$refs['newSystemUser'].validate((valid) => {
        if (valid) {
          const payload = {
            username: this.newSystemUserForm.username,
            password: 'password',
            email: this.newSystemUserForm.email,
            roles: this.formattedRoles,
            name: this.newSystemUserForm.name
          }
          this.createSystemUser(payload)
            .then((res) => {
              this.$refs['newSystemUser'].resetFields();
              console.log("Response", res)
              console.log("Response", payload.roles)
            })
            .catch((err) => {
              this.$toastr.error(err.response.message)
            })
        }
      })
    },
    search: debounce(function () {
      this.getAllUsers()
    }, 500),
    handleSizeChange() {
      this.$store.dispatch('handleSizeChange')
    },
    handleCurrentChange() {
      this.$store.dispatch('handleCurrentChange')
    },
    openModal() {
      this.dialogVisible = true
    }
  }
}
</script>

<style>
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #11141A;
  color: #FFF;
}

.el-table th>.cell {
  color: #11141A;
}

.w-input {
  width: 27rem;
}

.el-input.is-active .el-input__inner,
.el-input__inner:focus {
  border-color: #11141A;
  outline: 0;
}

#all-users {
  font-family: 'Lato', sans-serif;
}
</style>